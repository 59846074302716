<template>

    <div class="section event-tips" v-if="dataSet.data !== null">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-3 col-xl-4">
                    <div class="section-head mt-lg-40">
                        <h3 class="section-head__title">Veranstaltungstipps</h3>
                        <h4 class="section-head__info">Aktuelle Veranstaltungen und Angebote</h4>
                        <a href="/angebote" title="Mehr Veranstaltungstipps anzeigen"
                            class="btn mt20 d-none d-lg-block">MEHR ANZEIGEN</a>
                    </div>
                </div>

                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="row mobile--scrolling dropShadowWorkaround">
                        <div class="event-card d-flex align-items-stretch" v-for="(item, index) in dataSet.data"
                            :key="index">
                            <angebot-card :item="item" :noslider="true" />
                        </div>
                    </div>
                </div>
            </div>

            <a href="/angebote" title="Mehr Veranstaltungstipps anzeigen" class="btn btn-block mt30 d-lg-none">MEHR
                ANZEIGEN</a>
        </div>
    </div>

</template>

<script>

import { getContentsOfType } from '@/api/content';
import { showLoader, hideLoader } from '@/utils/helpers';

export default {
    name: "eventSection",
    components: {
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
    },
    data() {
        return {
            dataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 3,
                    type: "teaser",
                    selectRandomly: true,
                    selectedContentTypeId: this.$offerId,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
        }
    },
    created() {
        this.getContents();
    },
    methods: {
        showLoader,
        hideLoader,
        getContents() {
            this.dataSet.query.type = "view";
            var contentLoader = this.showLoader(contentLoader);
            const { limit, page } = this.dataSet.query;
            getContentsOfType(this.$offerId, this.dataSet.query).then(response => {
                const { data, meta } = response;
                this.dataSet.data = data;
                this.dataSet.data.forEach((element, index) => {
                    element['index'] = (page - 1) * limit + index + 1;
                });
                this.dataSet.meta = meta;
                this.dataSet.total = meta.total;
                this.tempContentCount = meta.total;
            })
                .finally(() => {
                    contentLoader = this.hideLoader(contentLoader);
                });
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.event-card {
    width: 325px;
    min-width: 325px;
    margin-left: 15px;

    &:last-child {
        margin-right: 15px;
    }
}

a.btn {
    color: #fff;
    max-width: 210px;

    &:hover {
        color: #C8D400;
        text-decoration: none;
    }
}

.dropShadowWorkaround {
    padding-bottom: 10px;
}

body[dir="rtl"] {
    .event-card {
        padding-right: 0px;

        &:first-child {
            padding-right: 15px;
        }

        &:last-child {
            margin-right: 0px;
            margin-left: 15px;
        }
    }
}
</style>
